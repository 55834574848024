import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { format, differenceInCalendarDays } from 'date-fns';
import ReactSelect from 'react-select';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

/* eslint-disable no-undef */
import { Controller, useForm } from 'react-hook-form';

import { toast } from 'react-hot-toast';
import { Grid, Paper, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { zodResolver } from '@hookform/resolvers/zod';
import { checkLeaveDate, checkWorkReturnDate, requestLeaveFormSchema } from '../header/helper';
import requestLeaveService from '../../../services/RequestLeaveService';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  control: {
    padding: theme.spacing(2)
  }
}));

const RequestLeaveForm = ({ open, setOpen, handleClose, setAnchorEl }) => {
  const classes = useStyles();

  // const [isSubmitting, setSubmitting] = useState(false);
  // const [disableUpdate, setDisableUpdate] = useState(false);

  const [leaveCategory] = useState([
    'Annual Leave',
    'Personal Leave',
    'Leave without pay',
    'Long Service Leave',
    'Rostered Day Off'
  ]);

  const leaveCategoryOptions = leaveCategory.map((l) => {
    return { value: l, label: l };
  });

  const {
    control,
    handleSubmit,
    // reset,
    // getValues,
    watch,
    formState: { errors }
  } = useForm({
    resolver: zodResolver(requestLeaveFormSchema),
    defaultValues: {
      leaveType: '',
      numberOfDays: 0,
      firstDayOfLeave: format(new Date(), 'yyyy-MM-dd'),
      lastDayOfLeave: format(new Date(), 'yyyy-MM-dd'),
      firstDayBackToWork: format(new Date(), 'yyyy-MM-dd')
    }
  });

  const onSubmit = (data) => {
    //check if last day of leave is earlier than last day of leave
    if (!checkLeaveDate(data.firstDayOfLeave, data.lastDayOfLeave)) {
      toast.error('The last day of leave should not be earlier than the first day of leave.');
    }
    if (!checkWorkReturnDate(data.lastDayOfLeave, data.firstDayBackToWork)) {
      toast.error('The first day back to work must be future date.');
    }
    const totalLeaveDays =
      differenceInCalendarDays(new Date(watch('lastDayOfLeave')), new Date(watch('firstDayOfLeave'))) + 1;

    const formData = {
      ...data,
      numberOfDays: totalLeaveDays,
      leaveRequestStatus: 'pending',
      employeeId: localStorage.getItem('userId'),
      employeeDisplayName: localStorage.getItem('name')
    };

    requestLeaveService
      .createLeaveRequest(formData)
      .then((data) => {
        toast.success("Successfully requested leave to the admin, you'll be notified once it gets approved/rejected");
      })
      .catch((error) => {
        const errorMessage = error?.response?.data?.message || 'Could not request leave with the admin.';
        toast.error(errorMessage);
      })
      .finally(() => {
        setOpen(false);
        setAnchorEl(false);
      });
  };

  return (
    <Grid item xs={12} style={{ padding: '0 0 10px 0' }}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="form-dialog-title" style={{ marginBottom: '-20px' }}>
          {'My Leave Request'}
        </DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Paper className={classes.control}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <h5> Leave Type</h5>
                  <Controller
                    name="leaveType"
                    control={control}
                    render={({ field }) => (
                      <>
                        <ReactSelect
                          className="basic-single"
                          classNamePrefix="select"
                          //  defaultValue={null}
                          value={leaveCategoryOptions?.find((option) => option.value === field.value) || null}
                          isSearchable={true}
                          name="leaveType"
                          options={leaveCategoryOptions}
                          // eslint-disable-next-line no-undef
                          menuPortalTarget={document.body}
                          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                          // onChange={(selectedOption) => {
                          //   return handleAssignedAssetItem(fi);
                          // }}
                          onChange={(selectedOption) => field.onChange(selectedOption ? selectedOption?.value : null)}
                        />
                        {errors.leaveType && (
                          <p
                            style={{
                              color: 'red',
                              marginTop: '10px',
                              fontSize: '0.8rem',
                              lineHeight: '1',
                              marginBottom: '0px',
                              paddingBottom: '0px'
                            }}
                          >
                            {errors?.leaveType?.message}
                          </p>
                        )}
                      </>
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <h5> First day of leave</h5>
                  <Controller
                    name="firstDayOfLeave"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        type="date"
                        id="firstDayOfLeave"
                        fullWidth
                        variant="outlined"
                        {...field}
                        error={!!errors.firstDayOfLeave}
                        helperText={errors.firstDayOfLeave?.message}
                        // disabled={isSubmitting || disableUpdate}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <h5> Last day of leave</h5>
                  <Controller
                    name="lastDayOfLeave"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        type="date"
                        id="lastDayOfLeave"
                        fullWidth
                        variant="outlined"
                        {...field}
                        error={!!errors.lastDayOfLeave}
                        helperText={errors.lastDayOfLeave?.message}
                        // disabled={isSubmitting || disableUpdate}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <h5> Number of days</h5>
                  <Controller
                    name="numberOfDays"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        type="number"
                        id="numberOfDays"
                        fullWidth
                        variant="outlined"
                        {...field}
                        value={
                          differenceInCalendarDays(
                            new Date(watch('lastDayOfLeave')),
                            new Date(watch('firstDayOfLeave'))
                          ) + 1
                        }
                        error={!!errors.numberOfDays}
                        helperText={errors.numberOfDays?.message}
                        disabled={true}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <h5> First day back to work</h5>
                  <Controller
                    name="firstDayBackToWork"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        type="date"
                        id="firstDayBackToWork"
                        fullWidth
                        variant="outlined"
                        {...field}
                        error={!!errors.firstDayBackToWork}
                        helperText={errors.firstDayBackToWork?.message}
                        // disabled={isSubmitting || disableUpdate}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Paper>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary" variant="outlined">
              Cancel
            </Button>
            <Button type="submit" color="primary" variant="contained" autoFocus>
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Grid>
  );
};

export default RequestLeaveForm;

RequestLeaveForm.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  handleClose: PropTypes.func,
  setAnchorEl: PropTypes.any
};
