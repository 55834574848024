import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end', // Center horizontally
    alignItems: 'center', // Center vertically
    gap: theme.spacing(2), // Add spacing between buttons if needed
    flexWrap: 'wrap', // Wrap buttons to new lines if needed
    '& > *': {
      margin: theme.spacing(1)
    }
  },

  legendWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    cursor: 'pointer'
  },

  buttonGreen: {
    backgroundColor: 'green',
    '&:hover': {
      backgroundColor: 'green'
    },
    width: '50px !important', // Force width to 20px
    minWidth: '50px !important' // Override Material-UI minWidth
  },

  buttonYellow: {
    backgroundColor: 'yellow',
    '&:hover': {
      backgroundColor: 'yellow'
    },
    width: '50px !important',
    minWidth: '50px !important'
  },
  buttonRed: {
    backgroundColor: 'red',
    '&:hover': {
      backgroundColor: 'red'
    },
    width: '50px !important',
    minWidth: '50px !important'
  },

  buttonAll: {
    backgroundColor: '#eeeeee',
    '&:hover': {
      backgroundColor: '#eeeeee'
    },
    width: '50px !important',
    minWidth: '50px !important'
  }
}));

const MapLegend = ({ filter, setFilter }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.legendWrapper}>
        <Button className={classes.buttonGreen} onClick={() => setFilter({ ...filter, legendColor: 'Green' })}></Button>
        <p style={{ fontSize: '13px' }}>Live Location within 10 minutes</p>
      </div>
      <div className={classes.legendWrapper}>
        <Button
          className={classes.buttonYellow}
          onClick={() => setFilter({ ...filter, legendColor: 'Yellow' })}
        ></Button>
        <p style={{ fontSize: '13px' }}>Last known location within a week</p>
      </div>
      <div className={classes.legendWrapper}>
        <Button className={classes.buttonRed} onClick={() => setFilter({ ...filter, legendColor: 'Red' })}></Button>
        <p style={{ fontSize: '13px' }}>No status for 1 week</p>
      </div>
      <div className={classes.legendWrapper}>
        <Button className={classes.buttonAll} onClick={() => setFilter({ ...filter, legendColor: 'All' })}></Button>
        <p style={{ fontSize: '13px' }}>Show all</p>
      </div>
    </div>
  );
};

export default MapLegend;

MapLegend.propTypes = {
  filter: PropTypes.shape({
    legendColor: PropTypes.string
  }),
  setFilter: PropTypes.func
};
