import React, { useEffect, useState } from 'react';
import useFetchEmployeeAssetRecords from './hooks/useFetchEmployeeAssetRecords';
import MyAssetTable from './table/MyAssetTable';
import employeeService from '../../services/EmployeeService';

import ReactSelect from 'react-select';

import assetService from '../../services/AssetService';
import { isNotificationDataValid } from './table/helper';
import notificationService from '../../services/NotificationService';
import toast from 'react-hot-toast';
import { Button } from '@material-ui/core';

const MyAsset = () => {
  const { employeeAssets, setEmployeeAssets } = useFetchEmployeeAssetRecords();

  const [filter, setFilter] = useState({
    searchQuery: ''
  });

  const [assetRecords, setAssetRecords] = useState([]);
  const [assetSelected, setAssetSelected] = useState(false);
  const [selectedAssetToRequest, setSelectedAssetToRequest] = useState();
  const [recipientType, setRecipientType] = useState(null);
  const [recipientDisplayName, setRecipientDisplayName] = useState([]);

  const [columns] = useState([
    { name: 'assetId', title: 'AssetId' },
    { name: 'tokenMac', title: 'TokenMac' },
    { name: 'make', title: 'Make' },
    { name: 'model', title: 'Model' },
    { name: 'description', title: 'Description' },
    { name: 'tradeSpecific', title: 'TradeSpecific' },
    { name: 'supplier', title: 'Supplier' },
    { name: 'category', title: 'Category' },
    { name: 'dateOfPurchase', title: 'DateOfPurchase' },
    { name: 'owner', title: 'Owner' },
    { name: 'operator', title: 'Operator' },
    { name: 'location', title: 'Location' },
    { name: 'locationDate', title: 'LocationDate' },
    { name: 'insuranceName', title: 'InsuranceName' },
    { name: 'insurancePolicy', title: 'InsurancePolicy' },
    { name: 'insuranceExpiry', title: 'InsuranceExpiry' },
    { name: 'maintenanceSchedule', title: 'MaintenanceSchedule' },
    { name: 'disposalDate', title: 'DisposalDate' },
    { name: 'image', title: 'Image' },
    { name: 'comments', title: 'Comments' }
  ]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const delayDebounceFn = setTimeout(() => {
      if (filter.searchQuery.length > 2) {
        assetService.findAssets(filter.searchQuery).then((assets) => {
          const assetRecords = assets?.data?.data;
          setAssetRecords(assetRecords);
        });
      }
    }, 300);

    // eslint-disable-next-line no-undef
    return () => clearTimeout(delayDebounceFn);
  }, [filter.searchQuery]);

  const assetRecordOptions = assetRecords?.map((a) => {
    // eslint-disable-next-line no-undef
    const employeeDisplayName = localStorage.getItem('name');
    if (a.assetStatus === 'onRequest' || employeeDisplayName === a.operator) {
      console.log('a.assetStatus', a.assetStatus);
      return {
        value: a.assetId,
        label: a.assetStatus ? `${a.assetId} ${a.description} (${a.assetStatus || ''})` : a.description,
        id: a.assetId,
        isDisabled: true
      };
    }

    return {
      value: a.assetId,
      label: `assetId: ${a.assetId}, ${a.description}, ${a.tokenMac}, ${a.make}, ${a.owner}, ${a.operator}, ${a.location}, ${a.category}, ${a.supplier}, ${a.model}`,
      id: a.assetId
    };
  });

  //choose asset to request for approval from admin or other users
  const handleSelectedAssetItem = async (selectedItem) => {
    setAssetSelected(true);
    setSelectedAssetToRequest(selectedItem);

    const assetMatchedEmployee = assetRecords?.filter((a) => a.assetId === selectedItem?.value)[0];

    if (!assetMatchedEmployee?.operator || assetMatchedEmployee?.operator === 'BOXSHALL GROUP') {
      //first fetch all the employee
      const data = await employeeService.getAdmins();
      const adminRecords = data.data.data;

      const adminNames = [];

      adminRecords.forEach((item) => {
        adminNames.push(item.name);
      });

      setRecipientType('admin');

      setRecipientDisplayName(adminNames);
    } else {
      setRecipientDisplayName([assetMatchedEmployee.operator]);
    }
  };

  //once asset is choose send approval request to admin or other user that matches the DisplayName with operator in asset
  const handleSendRequestToEmployee = (e) => {
    e.preventDefault();
    //who is requesting the asset
    // eslint-disable-next-line no-undef
    const senderDisplayName = localStorage.getItem('name');

    const assetId = assetRecords
      ?.filter((r) => r.assetId === selectedAssetToRequest.value)
      .map((r) => {
        return { assetId: r.assetId, description: r.description };
      });

    const notificationData = {
      assetId: assetId,
      senderDisplayName: senderDisplayName,
      recipientDisplayName: recipientDisplayName,
      isRead: false,
      type: 'assetApprovalRequest',
      notificationStatus: 'pending'
    };

    const validNotificationData = isNotificationDataValid(notificationData);

    if (!validNotificationData) {
      return toast.error('Recipient not found');
    }

    notificationService
      .createNotification(notificationData)
      .then((data) => {
        if (recipientDisplayName.length > 1) {
          return toast.success('Successfully sent request to admin');
        }
        if (recipientDisplayName.length === 1) {
          toast.success(`Successfully sent request to ${recipientDisplayName[0]}` || 'Successfully sent request');
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message || "Asset request hasn't sent");
      })
      .finally(() => {
        setRecipientDisplayName([]);
        setAssetSelected(false);
        setRecipientType(null);
        setFilter({ ...filter, searchQuery: '' });
      });
  };

  return (
    <div>
      <div>Choose the asset to request</div>

      <div style={{ marginBottom: '1rem' }}>
        <ReactSelect
          className="basic-single"
          classNamePrefix="select"
          isSearchable
          isClearable
          inputValue={filter.searchQuery} // Tie the search input value to ReactSelect
          onInputChange={(newValue) => setFilter({ ...filter, searchQuery: newValue })} // Handle search input change
          options={assetRecordOptions}
          onChange={(selectedOption) => {
            handleSelectedAssetItem(selectedOption ? { name: selectedOption.value, value: selectedOption.id } : null);
          }}
        />
      </div>

      <div style={{ marginBottom: '1rem' }}>
        {assetSelected && recipientDisplayName.length === 1 ? (
          <div>
            <Button color="primary" variant="contained" onClick={handleSendRequestToEmployee}>
              {`Send Request to ${recipientDisplayName[0]}`}
            </Button>
          </div>
        ) : (
          assetSelected &&
          recipientDisplayName.length > 1 &&
          recipientType !== null && (
            <div>
              <Button color="primary" variant="contained" onClick={handleSendRequestToEmployee}>
                Send request to admin
              </Button>
            </div>
          )
        )}
      </div>

      <MyAssetTable rows={employeeAssets} setRows={setEmployeeAssets} columns={columns} />
    </div>
  );
};

export default MyAsset;
