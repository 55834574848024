import { differenceInSeconds, parseISO, getUnixTime } from 'date-fns';
import { getDistance } from 'geolib'; // Only import getDistance directly

export function getFormattedTrackerRecords(trackerRecords) {
  return trackerRecords.map((trackerRecord) => ({
    lat: trackerRecord.position.latitude,
    long: trackerRecord.position.longitude,
    deviceName: trackerRecord.device.name,
    timestamp: trackerRecord.timestamp
  }));
}

export function getFormattedRecords(trackerRecords) {
  return trackerRecords.map((trackerRecord) => ({
    lat: trackerRecord.position.latitude,
    long: trackerRecord.position.longitude,
    createdAt: trackerRecord.createdAt,
    // engineIgnitionStatus: trackerRecord.engine?.ignition?.status || false,
    movementStatus: trackerRecord.movement?.status || false
  }));
}

// Function to calculate distances and time intervals
export function calculateDistancesAndIntervals(gpsData) {
  //need to calculate each day distance, gpsData has 1 month data of similar type

  // Calculate total distance traveled in meters
  let totalDistance = 0;
  for (let i = 0; i < gpsData.length - 1; i++) {
    const start = gpsData[i];
    const end = gpsData[i + 1];

    // Check if start or end positions are invalid (missing or zero)
    const startLat = start?.position?.latitude;
    const startLong = start?.position?.longitude;
    const endLat = end?.position?.latitude;
    const endLong = end?.position?.longitude;

    if (
      !startLat ||
      !startLong ||
      !endLat ||
      !endLong || // Check for null/undefined or zero
      startLat === 0 ||
      startLong === 0 ||
      endLat === 0 ||
      endLong === 0
    ) {
      continue; // Skip to the next segment
    }

    const distance = getDistance(
      { latitude: start?.position?.latitude, longitude: start?.position?.longitude },
      { latitude: end?.position?.latitude, longitude: end?.position?.longitude }
    );
    totalDistance += distance / 1000;
  }

  // Calculate total time in seconds
  const startDate = parseISO(gpsData[0].createdAt);
  const endDate = parseISO(gpsData[gpsData.length - 1].createdAt);
  const totalTimeInSeconds = differenceInSeconds(endDate, startDate);

  return Number(totalDistance).toFixed(2);
}

//separate vehicle history based on the  movement status true/false
export function getVehiclePoints(gpsData) {
  // Initialize an array to hold the grouped data
  const groupedData = [];
  let currentGroup = [];

  // Iterate through the movements array
  gpsData.forEach((data, index) => {
    // Create the data array for the current data
    // const dataEntry = [data?.lat, data?.long, data?.createdAt];
    const dataEntry = [data?.lat, data?.long];

    if (data?.movementStatus) {
      // If movementStatus is true, add the entry to the current group
      currentGroup.push(dataEntry); // Always add the true movementStatus entry
    } else {
      // If movementStatus is false
      if (currentGroup.length > 0) {
        // If there are true entries in the current group, add the false entry
        currentGroup.push(dataEntry);
      }
    }

    // When we find a false movementStatus after true statuses
    if (currentGroup.length > 0 && (data?.movementStatus === false || index === gpsData.length - 1)) {
      // Check if we should push the group (only when moving to false or at the end of array)
      if (data?.movementStatus === false || index === gpsData.length - 1) {
        groupedData.push(currentGroup); // Push current group to grouped data
        currentGroup = []; // Reset current group for the next series
      }
    }
  });

  // After iterating, check if there is any ongoing group to push
  if (currentGroup.length > 0) {
    groupedData.push(currentGroup);
  }

  // Output the structured data
  const polylineRecord = JSON.stringify(groupedData, null, 2);

  // Flatten the grouped data into an array of points for the polyline
  const points = groupedData
    .flat()
    .map((point) => {
      const latLng = [point[0], point[1]];
      // Log to ensure lat/long are valid
      return latLng;
    })
    .filter((point) => point[0] !== undefined && point[1] !== undefined); // Filter out undefined values

  return points;
}

//format to group trips
export function getFormattedTripRecord(trackerRecords) {
  return trackerRecords.map((trackerRecord) => ({
    position: { latitude: trackerRecord.position.latitude, longitude: trackerRecord.position.longitude },
    timestamp: trackerRecord.trackerRecord,
    createdAt: trackerRecord.createdAt,
    engineStatus: trackerRecord.engine?.ignition?.status || false,
    movementStatus: trackerRecord.movement?.status || false,
    speed: trackerRecord.position.speed
  }));
}

export function groupTripsByIgnitionAndMovement(data) {
  const trips = [];
  let currentTrip = [];
  const movementThreshold = 5; // Speed threshold in km/h for considering the vehicle is moving
  const idleTimeThreshold = 10 * 60 * 1000; // 10 minutes of idle time before trip is considered ended

  for (let i = 0; i < data.length; i++) {
    const currentPoint = data[i];
    const lastPoint = currentTrip[currentTrip.length - 1];

    // Check if this is the first point or if the vehicle is still moving
    if (currentTrip.length === 0 && currentPoint.engineStatus && currentPoint.speed > movementThreshold) {
      // Start a new trip
      currentTrip.push(currentPoint);
    } else if (currentPoint.engineStatus && currentPoint.speed > movementThreshold) {
      // Continue the trip if the vehicle is moving
      currentTrip.push(currentPoint);
    } else if (
      currentPoint.engineStatus === false ||
      (currentPoint.speed === 0 &&
        new Date(currentPoint?.timestamp) - new Date(lastPoint?.timestamp) > idleTimeThreshold)
    ) {
      // End the trip if the engine is off or vehicle is idle for too long
      trips.push(currentTrip);
      currentTrip = []; // Reset for new trip
    }
  }

  // Add the last trip if any
  if (currentTrip.length > 0) {
    trips.push(currentTrip);
  }

  return trips;
}

//convert seconds to minutes
export function formatDuration(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  console.log('`${hours} hours, ${minutes} minutes`', `${hours} hours, ${minutes} minutes`);
  return `${hours} hours, ${minutes} minutes`;
}

// Example usage
// const durationInSeconds = 8389;
// console.log(formatDuration(durationInSeconds)); // Output: "2 hours, 19 minutes"

export function withInTenMinutes(timestamp) {
  const currentTimestamp = getUnixTime(new Date());
  const tenMinutesAgo = currentTimestamp - 10 * 60;

  return timestamp >= tenMinutesAgo;
}

//if the last known location:timestamp is less than one Week, it needs to return true
export function isLessThanAWeek(timestamp) {
  const currentTimestamp = getUnixTime(new Date()); // Current time in seconds
  const oneWeekAgo = currentTimestamp - 7 * 24 * 60 * 60; // 7 days in seconds

  return timestamp > oneWeekAgo;
}
