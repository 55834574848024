import { startOfDay, lastDayOfWeek, addDays, isFuture } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

/**
 * Format timesheet data api response
 */
export function getFormattedTableRows(timesheetList) {
  return timesheetList.map((item) => ({
    id: item._id,
    StartTime: item.StartTime,
    FinishTime: item.FinishTime,
    Hours: item.Hours,
    BillableStatus: new Boolean(item.BillableStatus).toString(),
    EmployeeRef: item.EmployeeRef,
    CustomerRef: item.CustomerRef,
    ClassRef: item.ClassRef,
    Date: new Date(item.Date),
    Description: item.Description,
    variationNote: item.variationNote,
    Image: item.Image || '',
    status: item.status || '',
    workType: item.workType || '',
    KeyPayEmployeeId: item.KeyPayEmployeeId,
    VendorRef: item.VendorRef,
    jobTitle: item.jobTitle || '',
    email: item.email || '',
    timeSheetImageUrl: item.timeSheetImageUrl || ''
  }));
}

export function getFormattedCustomerRecords(customerRefList) {
  return customerRefList.map((item) => ({
    value: item.Id,
    name: item.FullyQualifiedName
  }));
}

export function getFormattedClassRecords(classRefList) {
  return classRefList.map((item) => ({
    value: item.Id,
    name: item.FullyQualifiedName
  }));
}

export function getFormattedVendorRecords(vendorRefList) {
  return vendorRefList.map((item) => ({
    value: item.Id,
    name: item.DisplayName
  }));
}

export function getFormattedBillableStatus() {
  return ['false', 'true'];
}

export function isBillableStatusValid(billableStatus, variationNote) {
  if (billableStatus === 'true' && variationNote) return true;

  if (billableStatus === 'false' && !variationNote) return true;

  return false;
}

export function isClientObjAndTaskObjValid(clientObj, taskObj) {
  if (clientObj && taskObj) return true;

  return false;
}

export function validateDescription(desc) {
  if (desc !== '' && desc.trim() !== '') return true;
  return false;
}

export function isMedicalStatusValid(medicalStatus, medicalCertificate) {
  if (medicalStatus && medicalCertificate) return true;

  if (!medicalStatus && !medicalCertificate) return true;

  return false;
}

export function isTimesheetFormDataValid(myTimeFormData) {
  if (!validateDescription(myTimeFormData.description)) {
    return false;
  }

  if (!isClientObjAndTaskObjValid(myTimeFormData.client, myTimeFormData.task)) {
    return false;
  }

  const timesheetPayload = {
    StartTime: myTimeFormData.startTime,
    FinishTime: myTimeFormData.finishTime,
    Date: myTimeFormData.date,
    Description: myTimeFormData.description,
    isBillable: myTimeFormData.isBillable,
    client: myTimeFormData.client,
    task: myTimeFormData.task,
    variationNote: myTimeFormData.variationNote,
    employeeRef: myTimeFormData.employeeRef ? myTimeFormData.employeeRef : ''
  };
  return timesheetPayload;
}

export function isLeaveTimesheetFormDataValid(myTimeFormData) {
  function validateWorkType(workType) {
    if (workType) return true;
  }

  if (!validateDescription(myTimeFormData.description) || !validateWorkType(myTimeFormData.workType)) {
    return false;
  }
  if (!isMedicalStatusValid(myTimeFormData.medicalStatus, myTimeFormData.timeSheetImageUrl)) {
    return false;
  }

  const timesheetPayload = {
    StartTime: myTimeFormData.startTime,
    FinishTime: myTimeFormData.finishTime,
    Date: myTimeFormData.date,
    Description: myTimeFormData.description,
    workType: myTimeFormData.workType,
    medicalStatus: myTimeFormData.medicalStatus,
    timeSheetImageUrl: myTimeFormData.timeSheetImageUrl
  };
  return timesheetPayload;
}

export function getFormattedItemRecords(itemRefList) {
  return itemRefList.map((item) => ({
    value: item.Id,
    name: item.FullyQualifiedName,
    Description: item.Description,
    ClassRef: item.ClassRef
  }));
}

export function isTimesheetDateInFuture(date) {
  const endDate = lastDayOfWeek(new Date(), { weekStartsOn: 3 });
  const timezone = 'Australia/melbourne';

  // Assuming enteredDate is the date entered by the user
  const enteredDate = new Date(date);
  // console.log('enteredDate', enteredDate);
  // console.log('date format', date);

  // console.log(
  //   'formatInTimeZone',
  //   formatInTimeZone(date, timezone, 'yyyy-MM-dd HH:mm:ss zzz') // 2014-10-25 12:46:20 GMT+2
  // );

  const formattedDate = formatInTimeZone(date, timezone, 'yyyy-MM-dd HH:mm:ss zzz');

  // Calculate the start date of the week
  const startDateOfWeek = startOfDay(endDate);
  startDateOfWeek.setDate(endDate.getDate() - 6);

  if (isFuture(formattedDate)) {
    return true;
  } else {
    return false;
  }

  // Check if enteredDate falls within the week
  // if (enteredDate >= startDateOfWeek.getTime() && enteredDate <= addDays(new Date(endDate), 1).getTime()) {
  //   console.log('The entered date falls within the week ending', enteredDate);
  //   return true;
  // } else {
  //   console.log('The entered date does not fall within the week ending', enteredDate);
  //   return false;
  // }

  //for now just check future date only
  // if (enteredDate <= addDays(new Date(endDate), 1).getTime()) {
  //   return true;
  // } else {
  //   return false;
  // }
}
