import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, Tooltip, Polyline, FeatureGroup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import L from 'leaflet';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

import VehicleTrackerItem from './VehicleTrackerItem';

import Histogram from './vehicleTrackerTimeline/Histogram';

import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import MapLegend from './legend/MapLegend';
import useFetchVehicleTrackerRecords from './hooks/useFetchVehicleTrackerRecords';

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

const useStyles = makeStyles({
  leafletInteractive: {
    strokeWidth: 5,
    stroke: '#03a9f4'
  }
});

const MapComponent = () => {
  const classes = useStyles();

  const latitude = -37.79869;
  const longitude = 144.89608;
  const position = [latitude, longitude];

  const [filter, setFilter] = useState({
    legendColor: 'All'
  });

  const { vehicleTrackerRecords, loading, setVehicleTrackerRecords } = useFetchVehicleTrackerRecords(filter);

  const [polylineRecord, setPolylineRecord] = useState([]);

  const [userDeviceName, setUserDeviceName] = useState(null);
  const [newDateClicked, setNewDateClicked] = useState(false);

  const text = '';

  // useEffect(() => {
  //   const fetchVehicleTrackerRecords = async () => {
  //     setLoading(true); // Start loading
  //     try {
  //       const vehicleResponse = await vehicleTrackerService.getTrackerRecords();
  //       const formattedRecords = getFormattedTrackerRecords(vehicleResponse.data.data);

  //       setVehicleTrackerRecords(formattedRecords);
  //     } catch (error) {
  //       setError('Failed to fetch vehicle tracker records'); // Set error
  //       // eslint-disable-next-line no-undef, no-console
  //       console.error(error);
  //     } finally {
  //       setLoading(false); // Stop loading
  //     }
  //   };

  //   fetchVehicleTrackerRecords();

  //   // Socket event handler for new tracker record
  //   const handleNewTrackerRecord = (newTrackerRecord) => {
  //     const newRecord = [];
  //     newRecord.push(newTrackerRecord);

  //     const formattedRecord = getFormattedTrackerRecords(newRecord)[0]; // Get the formatted record (single object)

  //     // Update the state to either replace the existing record or add a new one
  //     setVehicleTrackerRecords((prevRecords) => {
  //       // Check if a record with the same deviceName exists
  //       const recordExists = prevRecords.some((record) => record.deviceName === formattedRecord.deviceName);

  //       if (recordExists) {
  //         // Replace the existing record with the new one
  //         return prevRecords.map((record) =>
  //           record.deviceName === formattedRecord.deviceName ? formattedRecord : record
  //         );
  //       } else {
  //         // If no matching record exists, add the new one
  //         return [formattedRecord, ...prevRecords];
  //       }
  //     });
  //   };

  //   // Subscribe to new vehicle tracker records via Socket.io
  //   socket.on('newVehicleTrackerRecord', handleNewTrackerRecord);

  //   // Cleanup socket listeners when component unmounts
  //   return () => {
  //     socket.off('newVehicleTrackerRecord', handleNewTrackerRecord);
  //   };
  // }, []);

  if (loading) {
    return <div>Loading...</div>; // Show loading message
  }

  const handleUserHistory = (deviceName) => {
    setUserDeviceName(deviceName);
  };

  const handleCloseVehicleLocationDetails = () => {
    setUserDeviceName(null);
    setPolylineRecord([]);
    setNewDateClicked(false);
  };

  return (
    <div>
      <div>
        <MapLegend
          vehicleTrackerRecords={vehicleTrackerRecords}
          setVehicleTrackerRecords={setVehicleTrackerRecords}
          filter={filter}
          setFilter={setFilter}
        />
      </div>
      {userDeviceName ? (
        <div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '2rem', cursor: 'pointer' }}>
            <ClearIcon onClick={handleCloseVehicleLocationDetails} />
          </div>
          <div style={{ display: 'flex' }}>
            {/* showing polyline records */}
            <div style={{ display: 'flex', flex: '1' }}>
              <Histogram
                setPolylineRecord={setPolylineRecord}
                polylineRecord={polylineRecord}
                userDeviceName={userDeviceName}
                newDateClicked={newDateClicked}
                setNewDateClicked={setNewDateClicked}
              />
            </div>
            <div style={{ display: 'flex', flex: '2' }}>
              <MapContainer center={position} zoom={11} style={{ height: '100vh', width: '100%' }}>
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />

                <FeatureGroup>
                  {/* {polylineRecord?.map((mark, i) => (
                  // i === 0 || i === polylineRecord.length - 1 ? <Marker key={i} position={mark} /> : null
                  <Marker key={i} position={mark} />
                ))} */}

                  <Polyline positions={polylineRecord} color="blue" className={classes.leafletInteractive} />
                </FeatureGroup>
              </MapContainer>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <MapContainer center={position} zoom={13} style={{ height: '100vh', width: '100%' }}>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

            {vehicleTrackerRecords?.length > 0
              ? vehicleTrackerRecords.map((vehicleTrackerItem) => (
                  <VehicleTrackerItem
                    vehicleTrackerItem={vehicleTrackerItem}
                    key={vehicleTrackerItem.deviceName}
                    handleUserHistory={handleUserHistory}
                  />
                ))
              : // <Marker position={position}>
                //   <Popup>
                //     {text} <br />
                //   </Popup>
                //   <Tooltip direction="right" offset={[25, 20]} opacity={1} permanent>
                //     <span>{text}</span>
                //   </Tooltip>
                // </Marker>
                null}
          </MapContainer>
        </div>
      )}

      {/* showing map */}
    </div>
  );
};

export default MapComponent;
