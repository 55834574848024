import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  LineController,
  // Title,
  // Legend,
  Tooltip
} from 'chart.js';
Chart.register([
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  // Title,
  // Legend,
  LineController,
  Tooltip
]);

import { calculateDistancesAndIntervals, getFormattedRecords, getVehiclePoints } from '../helper/helper';
import vehicleTrackerService from '../../../services/VehicleTrackerService';

import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { Button } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import VehicleLocationDetails from './VehicleLocationDetails';
import { format } from 'date-fns';

const Histogram = ({ setPolylineRecord, userDeviceName, newDateClicked, setNewDateClicked }) => {
  const theme = useTheme();

  // Create a new Date object
  const todayDate = new Date();
  const options = {
    weekday: 'long', // Full day name (e.g., Friday)
    day: 'numeric', // Numeric day (e.g., 25)
    month: 'long', // Full month name (e.g., October)
    year: 'numeric' // Full year (e.g., 2024)
  };
  // // Format the date
  const formattedDate = todayDate.toLocaleDateString('en-GB', options);

  const [gpsRecord, setGpsRecord] = useState([]);
  const [gpsLoading, setGpsLoading] = useState(false);
  const [gpsError, setGpsError] = useState(null);

  const [skip, setSkip] = useState(0);
  const [clickedDate, setClickedDate] = useState(formattedDate);

  const [distanceRecord, setDistanceRecord] = useState([]);
  const [labels, setLabels] = useState([]);
  const [dailyKilometer, setDailyKilometer] = useState(0);
  const [dailyDuration, setDailyDuration] = useState(null);

  useEffect(() => {
    const fetchGpsRecord = async () => {
      setGpsLoading(true);
      try {
        const vehiclePayload = {
          skip: skip,
          deviceName: userDeviceName
        };
        const gpsData = await vehicleTrackerService.getAMonthRecords(vehiclePayload);
        const nestedGpsData = gpsData?.data?.data;
        setGpsRecord(nestedGpsData);

        const distanceArray = [];
        const labelArray = [];

        if (nestedGpsData?.length > 0) {
          for (let innerArray of nestedGpsData) {
            const totalDistance = calculateDistancesAndIntervals(innerArray);

            distanceArray.unshift(totalDistance);
            labelArray.unshift(innerArray[0]?.day);
          }
        }

        setDistanceRecord(distanceArray);
        setLabels(labelArray);
      } catch (error) {
        // eslint-disable-next-line no-console, no-undef
        console.error('error is:', error);
        setGpsError(error?.response?.data?.message);
      } finally {
        setGpsLoading(false);
      }
    };
    fetchGpsRecord();
  }, [skip, userDeviceName]);

  useEffect(() => {
    const fetchTodayGpsLocation = async () => {
      try {
        if (!newDateClicked && gpsRecord.length) {
          const formatDate = format(new Date(), 'yyyy-MM-dd');

          for (let innerArray of gpsRecord) {
            if (innerArray[0].day === formatDate) {
              const formattedRecords = getFormattedRecords(innerArray);

              let routeHistory = [];
              for (let i = 0; i <= formattedRecords.length; i++) {
                routeHistory.push(formattedRecords[i]);
                //create different points
              }

              const polylineRecord = getVehiclePoints(routeHistory);
              setPolylineRecord(polylineRecord);

              break; // Stop the loop after the first match
            }
          }

          const devicePayload = {
            deviceId: gpsRecord.length && gpsRecord[0][0]?.device?.id,
            dateString: formatDate
          };
          const data = await vehicleTrackerService.getDailyDistance(devicePayload);
          if (data?.data?.data) {
            setDailyKilometer(data?.data?.data);
          }

          const dailyDuration = await vehicleTrackerService.getTelemetryDailyDuration(devicePayload);
          if (dailyDuration?.data?.data) {
            setDailyDuration(dailyDuration?.data?.data);
          }

          setClickedDate(formatDate);
        }
      } catch (error) {
        // eslint-disable-next-line no-console, no-undef
        console.error('error is:', error);
      }
    };
    fetchTodayGpsLocation();
  }, [formattedDate, gpsRecord, newDateClicked, setPolylineRecord]);

  const handleNext = () => {
    setSkip((prevActiveStep) => prevActiveStep - 15);
  };

  const handleBack = () => {
    setSkip((prevActiveStep) => prevActiveStep + 15);
  };

  const data = {
    labels: labels.length > 0 ? labels : ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5', 'Day 6', 'Day 7'],
    datasets: [
      {
        label: 'Distance in kms',
        backgroundColor: '#4285f4',
        borderColor: '#4285f4',
        borderWidth: 1,
        hoverBackgroundColor: '#03a9f4',
        hoverBorderColor: '#03a9f4',
        data: distanceRecord
      }
    ]
  };

  const handleClickedDateHistory = async (clickedDate) => {
    setNewDateClicked(true);
    for (let innerArray of gpsRecord) {
      if (innerArray[0].day === clickedDate) {
        const formattedRecords = getFormattedRecords(innerArray);

        let routeHistory = [];
        for (let i = 0; i <= formattedRecords.length; i++) {
          routeHistory.push(formattedRecords[i]);
          //create different points
        }

        const polylineRecord = getVehiclePoints(routeHistory);
        setPolylineRecord(polylineRecord);

        break; // Stop the loop after the first match
      }
    }

    const devicePayload = {
      deviceId: gpsRecord[0][0]?.device?.id,
      dateString: clickedDate
    };
    const data = await vehicleTrackerService.getDailyDistance(devicePayload);
    if (data?.data?.data) {
      setDailyKilometer(data?.data?.data);
    }

    const dailyDuration = await vehicleTrackerService.getTelemetryDailyDuration(devicePayload);
    if (dailyDuration?.data?.data) {
      setDailyDuration(dailyDuration?.data?.data);
    }
  };

  if (gpsLoading) {
    return <>Loading...</>;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex' }}>
        <div style={{ minWidth: '15px', paddingRight: '5px', marginTop: '2rem' }}>
          <Button onClick={handleBack}>
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
          </Button>
        </div>

        <div>
          <Bar
            data={data}
            width={null}
            height={200}
            options={{
              responsive: true,
              plugins: {
                tooltip: {
                  enabled: true,
                  callbacks: {
                    label: function (evt) {
                      return `${evt.formattedValue} kms`;
                    }
                  }
                }
                // legend: {
                //   position: 'top',
                //   display: true
                // }
                // title: {
                //   display: true,
                //   text: 'Chart.js Bar Chart',
                //   position: 'top'
                // }
              },
              // scales: {
              //   x: {
              //     display: false
              //     // ticks: {
              //     //   callback: function(label) {
              //     //     return `${this.getLabelForValue(label)}`
              //     //   }
              //     // }
              //   },
              //   y: {
              //     display: false
              //   }
              // },

              onClick: (evt, element) => {
                if (element.length > 0) {
                  setClickedDate(labels[element[0].index]);
                  handleClickedDateHistory(labels[element[0].index]);
                }
              }
            }}
          />
        </div>

        <div
          style={{
            minWidth: '31px',
            marginTop: '2rem'
          }}
        >
          <Button onClick={handleNext} disabled={skip === 0}>
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </Button>
        </div>
      </div>

      {/* details of the maps, show the locations with the time */}
      <div style={{ marginTop: '1rem' }}>
        <VehicleLocationDetails
          clickedDate={clickedDate}
          gpsRecord={gpsRecord}
          dailyKilometer={dailyKilometer}
          dailyDuration={dailyDuration}
        />
      </div>
    </div>
  );
};

export default Histogram;

Histogram.propTypes = {
  setPolylineRecord: PropTypes.func,
  userDeviceName: PropTypes.any,
  newDateClicked: PropTypes.bool,
  setNewDateClicked: PropTypes.func
};
