import { useEffect, useState } from 'react';
import vehicleTrackerService from '../../../services/VehicleTrackerService';
import { getFormattedTrackerRecords, isLessThanAWeek, withInTenMinutes } from '../helper/helper';

import { socket } from '../../../socket';

export default function useFetchVehicleTrackerRecords(filter) {
  const [vehicleTrackerRecords, setVehicleTrackerRecords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchVehicleTrackerRecords = async () => {
      setLoading(true); // Start loading
      try {
        const vehicleResponse = await vehicleTrackerService.getTrackerRecords();
        const formattedRecords = getFormattedTrackerRecords(vehicleResponse.data.data);

        let filteredVehicleTracker = formattedRecords.filter((record) => {
          if (!record.timestamp) return false;

          if (filter.legendColor === 'All') return true;

          if (filter.legendColor === 'Green' && withInTenMinutes(record.timestamp)) return true;

          if (filter.legendColor === 'Yellow' && isLessThanAWeek(record.timestamp)) return true;

          if (filter.legendColor === 'Red' && !withInTenMinutes(record.timestamp) && !isLessThanAWeek(record.timestamp))
            return true;

          return false;
        });

        setVehicleTrackerRecords(filteredVehicleTracker);
      } catch (error) {
        setError('Failed to fetch vehicle tracker records'); // Set error
        // eslint-disable-next-line no-undef, no-console
        console.error(error);
      } finally {
        setLoading(false); // Stop loading
      }
    };

    fetchVehicleTrackerRecords();

    // Socket event handler for new tracker record
    const handleNewTrackerRecord = (newTrackerRecord) => {
      const newRecord = [];
      newRecord.push(newTrackerRecord);

      const formattedRecord = getFormattedTrackerRecords(newRecord)[0]; // Get the formatted record (single object)

      // Update the state to either replace the existing record or add a new one
      setVehicleTrackerRecords((prevRecords) => {
        // Check if a record with the same deviceName exists
        const recordExists = prevRecords.some((record) => record.deviceName === formattedRecord.deviceName);

        if (recordExists) {
          // Replace the existing record with the new one
          return prevRecords.map((record) =>
            record.deviceName === formattedRecord.deviceName ? formattedRecord : record
          );
        } else {
          // If no matching record exists, add the new one
          return [formattedRecord, ...prevRecords];
        }
      });
    };

    // Subscribe to new vehicle tracker records via Socket.io
    socket.on('newVehicleTrackerRecord', handleNewTrackerRecord);

    // Cleanup socket listeners when component unmounts
    return () => {
      socket.off('newVehicleTrackerRecord', handleNewTrackerRecord);
    };
  }, [filter.legendColor]);

  return { vehicleTrackerRecords, loading, error, setVehicleTrackerRecords };
}
