import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import { getFormattedTripRecord, groupTripsByIgnitionAndMovement } from '../helper/helper';
import { Divider } from '@material-ui/core';

const VehicleLocationDetails = ({ clickedDate, gpsRecord, dailyKilometer, dailyDuration }) => {
  const [loading, setLoading] = useState(false);
  const [vehicleTripPoints, setVehicleTripPoints] = useState([]);
  const [tripAddresses, setTripAddresses] = useState([]);

  useEffect(() => {
    if (gpsRecord.length > 0) {
      const initializeData = () => {
        for (let innerArray of gpsRecord) {
          if (innerArray[0].day === clickedDate) {
            const formattedRecords = getFormattedTripRecord(innerArray);
            const polylineRecord = groupTripsByIgnitionAndMovement(formattedRecords);
            const filteredRecords = polylineRecord.filter((record) => record.length > 0);
            setVehicleTripPoints(filteredRecords);
            setTripAddresses(new Array(filteredRecords.length).fill('Loading...')); // Initial state for addresses
            break;
          }
        }
      };

      initializeData();
    }
  }, [gpsRecord, clickedDate]);

  useEffect(() => {
    if (vehicleTripPoints.length === 0) return;

    let index = 0; // Initialize an index to track the current trip point for fetching

    // eslint-disable-next-line no-undef
    const intervalId = setInterval(async () => {
      if (index >= vehicleTripPoints.length) {
        // eslint-disable-next-line no-undef
        clearInterval(intervalId);
        return;
      }

      const trip = vehicleTripPoints[index];
      if (trip.length > 0) {
        const { latitude, longitude } = trip[0].position;

        try {
          const response = await axios.get(
            `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
          );
          const data = response.data;
          const address =
            data && data.name
              ? data.name
              : data.display_name
              ? data.display_name
              : `Address not found at latitude ${latitude} and longitude ${longitude}`;

          // Update the specific address in tripAddresses
          setTripAddresses((prevAddresses) => {
            const updatedAddresses = [...prevAddresses];
            updatedAddresses[index] = address;
            return updatedAddresses;
          });
        } catch (error) {
          setTripAddresses((prevAddresses) => {
            const updatedAddresses = [...prevAddresses];
            updatedAddresses[index] = 'Error fetching address';
            return updatedAddresses;
          });
        } finally {
          setLoading(false);
        }
      }

      index += 1; // Move to the next trip point
    }, 2000); // Fetch an address every 2 seconds

    // Clear interval when component unmounts
    // eslint-disable-next-line no-undef
    return () => clearInterval(intervalId);
  }, [vehicleTripPoints]);

  return (
    <div style={{ padding: '1rem', border: '1px solid #ddd', borderRadius: '8px', maxWidth: '90%' }}>
      <h3>{clickedDate}</h3>
      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <DriveEtaIcon color="primary" />
        <div>
          <h3>{dailyKilometer} km</h3>
          <h3>{dailyDuration}</h3>
        </div>
      </div>
      <div>
        {loading ? (
          <p>Loading addresses...</p>
        ) : (
          <ul>
            {tripAddresses.map((address, index) => (
              <React.Fragment key={index}>
                <li style={{ listStyleType: 'none' }}>{address}</li>

                {tripAddresses.length - 1 !== index && (
                  <Divider
                    orientation="vertical"
                    flexItem
                    style={{ height: '3rem', width: '5px', marginLeft: '3rem', backgroundColor: '#03a9f4' }}
                  />
                )}
              </React.Fragment>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default VehicleLocationDetails;

VehicleLocationDetails.propTypes = {
  clickedDate: PropTypes.any,
  gpsRecord: PropTypes.any,
  dailyKilometer: PropTypes.any,
  dailyDuration: PropTypes.any
};
